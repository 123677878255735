<template>
  <div class="main gray-bg has-bottom">
    <van-nav-bar class="top-bar" title="修改密码" left-arrow @click-left="onClickLeft"/>
    <van-notice-bar color="#CC8B00" background="#FFF6E5" left-icon="warning">密码至少6位</van-notice-bar>
    <validator-form class="label-form" @valid-success="onSubmit" :rules="userFormRules">
      <van-cell-group>
        <validator-field label="原密码" v-model="query.old_password" type="password" :validator="userFormRules.old_password" placeholder="请输入原密码" name="old_password" clearable/>
        <validator-field label="新密码" v-model="query.password" type="password" :validator="userFormRules.password" placeholder="请输入新密码" name="password" clearable/>
        <validator-field v-model="query.password_confirm" type="password" :validator="userFormRules.password_confirm"
                   label="确认密码" name="password_confirm" placeholder="请再次确认密码" clearable/>
      </van-cell-group>
      <div class="form-bottom">
        <van-button type="primary" native-type="submit" block>确定</van-button>
      </div>
    </validator-form>
  </div>
</template>

<script>
import { CellGroup, NavBar,NoticeBar,Button,Toast} from 'vant';
import {reactive} from 'vue';
import ValidatorForm from "@/components/ValidatorForm";
import ValidatorField from "@/components/ValidatorField";
import "@/style/common.css"
import {changePass} from "@/api/user";
import {useRouter} from "vue-router";
export default {
  name: "Password",
  components: {
    ValidatorForm,
    ValidatorField,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    [NoticeBar.name]: NoticeBar,
    [Button.name]: Button,
  },
  setup() {
    const query = reactive({
      old_password: "",
      password: "",
      password_confirm: ""
    })
    const onClickLeft = () => history.back();
    const userFormRules = {
      old_password: {
        label: '旧密码',
        rules: {
          "required": "请输入旧密码",
          "password": true,
        }
      },
      password:{
        label: '新密码',
        rules: {
          "required": "请输入新密码",
          "password": true,
        }
      },
      password_confirm:{
        label: '确认密码',
        rules: {
          "required": "请再次输入密码",
          "password": true,
        }
      }
    };
    const router = useRouter()
    const onSubmit = () => {
      changePass(query).then((res)=>{
        Toast(res.message);
        setTimeout(()=>{
          router.replace('/personal/person')
        },2000)
      })
    };

    return {
      query,
      userFormRules,
      onClickLeft,
      onSubmit,
    };
  },
}
</script>

<style scoped>
</style>
